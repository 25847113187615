import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import clsx from "clsx";
import { format, isSameDay, parseISO } from "date-fns";

import {
    AccountCircleOutlined,
    ClearOutlined,
    KeyboardDoubleArrowUpOutlined,
    PauseCircleOutlineOutlined,
    SmartToyOutlined,
    SupervisedUserCircleOutlined,
    WhatsApp
} from "@mui/icons-material";
import {
    Avatar,
    Badge,
    Divider,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Can } from "../Can";
const StyledBadge = withStyles((theme) => ({
    badge: {
        width: 22,
        height: 22,
        backgroundColor: theme.palette.badgesWhatsApp.main,
        color: theme.palette.badgesWhatsApp.main,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        position: "absolute",
        top: 10,
        left: 18,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 2.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""'
        }
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1
        },
        "100%": {
            transform: "scale(1.6)",
            opacity: 0
        }
    }
}))(Badge);

const useStyles = makeStyles(theme => ({
    acceptButton: {
        flex: "none",
        marginLeft: "12px",
        marginRight: "-12px"
    },
    contactNameWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    lastMessageTime: {
        justifySelf: "flex-end",
    },
    contactLastMessage: {
        paddingRight: 20,
    },
    icon: {
        color: "#fff",
        width: 18,
        height: 18,
    },
    infoTag: {
        color: theme.palette.primary.main,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        padding: "2px 6px 1px",
        marginLeft: 5,
        borderRadius: "10vh",
    },
    infoTagAlternate: {
        background: theme.palette.error.main,
        color: theme.palette.primary.contrastText,
        border: "1px solid",
        borderColor: theme.palette.error.main,
        padding: "2px 6px 1px",
        whiteSpace: "nowrap",
        display: "block",
        marginLeft: 5,
        borderRadius: "10vh",
    },
    infoTagNPS: {
        // background: theme.palette.primary.main,
        // color: theme.palette.primary.contrastText,
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        fontWeight: "bold",
        padding: "1px 6px 1px",
        whiteSpace: "nowrap",
        display: "block",
        marginLeft: 5,
        borderRadius: "10vh",
        "&::before": {
            content: "'★'"
        }
    },
    infoTagIcon: {
        cursor: "pointer",
        '& svg': {
            height: "0.65em",
            maxWidth: "0.75em",
            marginLeft: 5,
            color: theme.palette.primary.main,
            transform: "scale(1.3) translate(0px, 2px)",
        },
    },
    infoTagIconAlternate: {
        cursor: "pointer",
        '& svg': {
            height: "0.65em",
            maxWidth: "0.75em",
            marginLeft: 5,
            color: theme.palette.primary.main,
            transform: "scale(1.4) translate(0px, 2px)",
        },
    },
    infoTagSecondary: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: "1px 6px 0px",
        marginLeft: 5,
        // transform: "scale(1) translate(0px, 1px)",
        borderRadius: theme.spacing(2),
    },
    infoTags: {
        display: "flex",
        justifyContent: "space-between",
        alignSelf: "center",
        marginLeft: "auto",
        fontSize: "0.75em",
    },
    pendingTicket: {
        cursor: "unset",
    },
    ticket: {
        position: "relative",
    },
    ticketDanger: {
        backgroundColor: theme.palette.danger.main,
    },
    ticketListDivider: {
        marginLeft: 0,
    },
    ticketQueueColor: {
        flex: "none",
        width: "8px",
        height: "100%",
        position: "absolute",
        top: "0%",
        left: "0%",
    },
    ticketWarning: {
        backgroundColor: theme.palette.warning.main,
    },
}));

const TicketListItem = ({ ticket, nps }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { uuid } = useParams();
    const { tenant, tenantId, userTenant } = useTenant();

    const [acceptTicketWithouSelectQueueOpen, setAcceptTicketWithouSelectQueueOpen] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    const navigate = useNavigate();
    let isMounted;

    const handleUpdateTicketStatus = async (e, ticket, status) => {
        isMounted = true;
        const controller = new AbortController();

        try {
            setLoading(true);
            let data = { status: status }
            if (status === "open") {
                data = { ...data, acceptAt: Date.now() }
            };
            if (status !== "pending") {
                data = { ...data, userTenantId: userTenant?.id || null }
            };

            if (tenantId) {
                await axiosPrivate.put(`/${tenantId}/tickets/${ticket.id}`, data, { signal: controller.signal });
            }

            if (isMounted) {
                setLoading(false)

                if (status === "closed" || status === "waiting" || status === "pending") {
                    navigate(`/${tenant.slug}/tickets`);
                } else {
                    navigate(`/${tenant.slug}/tickets/${ticket.uuid}`)
                }
            }
        } catch (err) {
            if (isMounted) setLoading(false)
            toastError(err, "", "Couldn't update ticket status at Ticket List Item");
        }

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    const handleSelectTicket = (e, ticket) => {
        // Checks if the button clicked is accept button, only act if not that.
        if (e.target.name !== "acceptButton") {
            navigate(`/${tenant.slug}/tickets/${ticket.uuid}`)
        }
    };

    const queueName = selectedTicket => {
        let name = null;
        let color = null;
        userTenant?.queues?.forEach(q => {
            if (q.id === selectedTicket.queueId) {
                name = q.name;
                color = q.color;
            }
        });
        return {
            name,
            color
        };
    }

    const handleOpenAcceptTicketWithouSelectQueue = () => {
        setAcceptTicketWithouSelectQueueOpen(true);
    };

    return (
        <>
            <AcceptTicketWithouSelectQueue
                modalOpen={acceptTicketWithouSelectQueueOpen}
                onClose={() => setAcceptTicketWithouSelectQueueOpen(false)}
                ticket={ticket}
            />
            <ListItemButton
                dense
                onClick={e => handleSelectTicket(e, ticket)}
                name="listItem"
                className={
                    ticket?.alertLevel
                        ? ticket.alertLevel === 0
                            ? clsx({ [classes.pendingTicket]: ticket.status === "pending" })
                            : ticket.alertLevel === 1
                                ? clsx(classes.ticketWarning, {
                                    [classes.pendingTicket]: ticket.status === "pending",
                                })
                                : clsx(classes.ticketDanger, {
                                    [classes.pendingTicket]: ticket.status === "pending",
                                })
                        : clsx(classes.ticket, {
                            [classes.pendingTicket]: ticket.status === "pending",
                        })}
                selected={uuid && uuid === ticket.uuid}
            >
                <Tooltip
                    arrow
                    placement="right"
                    title={ticket.queue?.name || queueName(ticket)?.name || i18n.t("translation.ticketsList.items.queueless")}
                >
                    <span
                        style={{ backgroundColor: ticket.queue?.color || queueName(ticket)?.color || "#7C7C7C" }}
                        className={classes.ticketQueueColor}
                    ></span>
                </Tooltip>
                <ListItemAvatar>
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.ticketsList.connectionTitle") + ticket.whatsapp?.name}
                    >
                        <StyledBadge
                            className={classes.badge}
                            overlap="circular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            badgeContent={<WhatsApp className={classes.icon} />}
                        >
                            <Avatar
                                src={ticket?.contact?.profilePicUrl}
                                alt={ticket?.contact?.name}
                                variant="rounded"
                            />
                        </StyledBadge>
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <span className={classes.contactNameWrapper}>
                            <Typography
                                noWrap
                                component="span"
                                variant="body2"
                                color="textPrimary"
                                className="font-semibold opacity-75"
                            >
                             <span className="text-teal-700 " ># {ticket.protocol}</span> -   {ticket.contact.name}
                            </Typography>
                            {ticket.lastMessage && (
                                <Typography
                                    className={classes.lastMessageTime}
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                                        <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                                    ) : (
                                        <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                                    )}
                                </Typography>
                            )}
                        </span>
                    }
                    secondary={
                        <span className={classes.contactNameWrapper}>
                            <Typography
                                className={classes.contactLastMessage}
                                noWrap
                                component="span"
                                variant="body2"
                                color="textSecondary"
                            >
                                {ticket.lastMessage ? (
                                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                                ) : (
                                    <br />
                                )}
                            </Typography>

                            <div className={classes.infoTags}>
                                { ticket.priority !== 0  && (
                                    <Tooltip arrow title={i18n.t("translation.ticketsList.priorityTitle")}>
                                        <div className={classes.infoTagIcon}>
                                            <KeyboardDoubleArrowUpOutlined color="primary" size="small" className={classes.icon} />
                                        </div>
                                    </Tooltip>
                                )}
                                {ticket.selectedBot && (
                                    <Tooltip arrow title={i18n.t("translation.ticketsList.botTitle")}>
                                        <div className={classes.infoTagIcon}><SmartToyOutlined /></div>
                                    </Tooltip>
                                )}
                                {ticket.userTenantId && (
                                    <Tooltip arrow title={i18n.t("translation.ticketsList.userTitle") + ticket?.userTenant?.user?.name}>
                                        <div className={classes.infoTagIcon}><AccountCircleOutlined /></div>
                                    </Tooltip>
                                )}
                                {ticket.status === "waiting" && (
                                    <Tooltip arrow title={i18n.t("translation.tickets.tabs.waiting.badge")}>
                                        <div className={classes.infoTagIcon}><PauseCircleOutlineOutlined /></div>
                                    </Tooltip>
                                )}
                                {ticket.isGroup === true && (
                                    <Tooltip arrow title={i18n.t("translation.tickets.tabs.isGroup.badge")}>
                                        <div className={classes.infoTagIcon}><SupervisedUserCircleOutlined /></div>
                                    </Tooltip>
                                )}
                                {ticket.unreadMessages !== 0 && (
                                    <Tooltip arrow title={i18n.t("translation.ticketsList.unreadMessages")}>
                                        <div className={classes.infoTagSecondary}>{ticket.unreadMessages}</div>
                                    </Tooltip>
                                )}
                                {(ticket.status === "open" || ticket.status === "pending") && (
                                     <Can
                                     role={userTenant?.role}
                                     perform="ticket-options:closed"
                                     yes={() => (
                                         <>
                                            <Tooltip arrow title={i18n.t("translation.ticketsList.closeTicketTitle")}>
                                        <div className={classes.infoTagIconAlternate} onClick={e => handleUpdateTicketStatus(e, ticket, "closed")}><ClearOutlined /></div>
                                    </Tooltip>
                                         </>
                                     )}
                                 />

                                )}
                                {ticket.status === "closed" && (
                                    <div className={classes.infoTagAlternate}>{i18n.t("translation.tickets.tabs.closed.badge")}</div>
                                )}
                                {nps?.score && userTenant?.role !== "user" && ( // TODO: do a better job on hiding from users.
                                    <Tooltip
                                        arrow
                                        placement="bottom"
                                        title={i18n.t("translation.dashboard.nps.tooltip")}
                                    >
                                        <div className={classes.infoTagNPS}>{nps.score}</div>
                                    </Tooltip>
                                )}
                            </div>

                        </span>
                    }
                />
                {(ticket.status === "pending" && ticket.queue !== null) && (
                    <ButtonWithSpinner
                        color="primary"
                        variant="outlined"
                        className={classes.acceptButton}
                        name="acceptButton"
                        size="small"
                        loading={loading}
                        onClick={e => handleUpdateTicketStatus(e, ticket, "open")}
                    >
                        {ticket.isGroup ? i18n.t("translation.validation.buttons.enter") : i18n.t("translation.validation.buttons.accept")}
                    </ButtonWithSpinner>
                )}
                {(ticket.status === "pending" && (ticket.queue === null || ticket.queue === undefined)) && (
                    <ButtonWithSpinner
                        color="secondary"
                        variant="outlined"
                        className={classes.acceptButton}
                        name="acceptButton"
                        size="small"
                        loading={loading}
                        onClick={e => handleOpenAcceptTicketWithouSelectQueue()}
                    >
                        {ticket.isGroup ? i18n.t("translation.validation.buttons.enter") : i18n.t("translation.validation.buttons.accept")}
                    </ButtonWithSpinner>
                )}
                {ticket.status === "waiting" && (
                    <ButtonWithSpinner
                        color="primary"
                        variant="contained"
                        className={classes.acceptButton}
                        size="small"
                        loading={loading}
                        onClick={e => handleUpdateTicketStatus(e, ticket, "open")}
                    >
                        {i18n.t("translation.validation.buttons.update")}
                    </ButtonWithSpinner>

                )}
                {/* {ticket.status === "closed" && (
					<ButtonWithSpinner
						color="primary"
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleUpdateTicketStatus(e, ticket, "open")}
					>
						{i18n.t("translation.validation.buttons.reopen")}
					</ButtonWithSpinner>

				)} */}
            </ListItemButton>
            <Divider
                variant="inset"
                component="li"
                className={classes.ticketListDivider}
            />
        </>
    );
};

export default TicketListItem;
