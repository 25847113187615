import { format, parseISO } from "date-fns";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
    CheckCircle,
    CloudSync,
    ContactsOutlined,
    DeleteOutline,
    EditOutlined,
    RefreshOutlined
} from "@mui/icons-material";
import {
    Button,
    Grid,
    IconButton,
    Paper,
    Tooltip
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid, gridClasses } from "@mui/x-data-grid";

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

// Components
import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import QrcodeModal from "../../components/QrcodeModal";
import StatusTooltip from "../../components/StatusTooltip";
import TutorialModalButton from "../../components/TutorialModalButton";
import WhatsAppModal from "../../components/WhatsAppModal";

// Context
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

// Hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import usePlan from "../../hooks/usePlan";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles(theme => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    // actionsCell: {
    //     minWidth: 140
    // },
    alignCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    // buttonProgress: {
    //     color: theme.palette.secondary.main,
    // },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    // customTableCell: {
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    // },
    defaultConnectionIcon: {
        borderRadius: "50%",
        // color: green[500]
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    spaceRight: {
        marginRight: theme.spacing(1),
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        maxWidth: 450,
    },
    // tooltipPopper: {
    //     textAlign: "center",
    // },
}));

const Connections = () => {
    const classes = useStyles();

    // Context
    const { tenantId, userTenant } = useTenant();
    const { getPlanValue } = usePlan();
    const { whatsApps, loading } = useContext(WhatsAppsContext);

    // State
    const [importJobs, setImportJobs] = useState([]);
    const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
    const [qrModalOpen, setQrModalOpen] = useState(false);
    const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const confirmationModalInitialState = {
        action: "",
        title: "",
        message: "",
        whatsAppId: "",
        open: false,
    };
    const [confirmModalInfo, setConfirmModalInfo] = useState(
        confirmationModalInitialState
    );

    const [rows, setRows] = useState([{
        getHistoryStatus: 'done',
        id: '',
        isDefault: '',
        name: '',
        number: '',
        status: '',
        updatedAt: '',
        whatsapp: ''
    }]);

    useEffect(() => {
        if (!whatsApps) return;

        let dataGridRows = whatsApps.map(w => ({
            getHistoryStatus: w?.getHistoryStatus,
            id: w?.id,
            isDefault: w?.isDefault,
            name: w?.name,
            number: w?.number,
            status: w?.status,
            updatedAt: w?.updatedAt,
            whatsapp: w
        }));
        setRows(dataGridRows)
    }, [whatsApps, loading]);

    const axiosPrivate = useAxiosPrivate();

    const handleStartWhatsAppSession = async whatsAppId => {
        if (!tenantId) return;
        try {
            await axiosPrivate.post(`/${tenantId}/whatsappsession/${whatsAppId}`);
        } catch (err) {
            toastError(err, "", "Error trying to start a WhatsApp session on connection's page.");
        }
    };

    const handleRestartWhatsAppSession = async whatsAppId => {
        if (!tenantId) return;
        try {
            await axiosPrivate.post(`/${tenantId}/whatsappsession/${whatsAppId}`, {
                action: 'reset'
            });
        } catch (err) {
            toastError(err, "", "Error trying to reset a WhatsApp session on connection's page.");
        }
    };

    const handleRequestNewQrCode = async whatsAppId => {
        if (!tenantId) return;
        try {
            await axiosPrivate.put(`/${tenantId}/whatsappsession/${whatsAppId}`);
        } catch (err) {
            toastError(err, "", "Error trying to get new QR Code for a WhatsApp session on connection's page.");
        }
    };

    const handleOpenWhatsAppModal = () => {
        setSelectedWhatsApp(null);
        setWhatsAppModalOpen(true);
    };

    const handleCloseWhatsAppModal = useCallback(() => {
        setWhatsAppModalOpen(false);
        setSelectedWhatsApp(null);
    }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

    const handleOpenQrModal = whatsApp => {
        setSelectedWhatsApp(whatsApp);
        setQrModalOpen(true);
    };

    const handleCloseQrModal = useCallback(() => {
        setSelectedWhatsApp(null);
        setQrModalOpen(false);
    }, [setQrModalOpen, setSelectedWhatsApp]);

    const handleEditWhatsApp = whatsApp => {
        setSelectedWhatsApp(whatsApp);
        setWhatsAppModalOpen(true);
    };

    const handleImportContacts = async (whatsAppId) => {
        if (!tenantId) return;
        try {
            toast.success(i18n.t("translation.contacts.toasts.import"))
            await axiosPrivate.post(`/${tenantId}/contacts/import/${whatsAppId}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenConfirmationModal = (action, whatsAppId) => {
        if (action === "disconnect") {
            setConfirmModalInfo({
                action: action,
                title: i18n.t("translation.connections.confirmationModal.disconnect.title"),
                message: i18n.t("translation.connections.confirmationModal.disconnect.message"),
                whatsAppId: whatsAppId,
            });
        }

        if (action === "delete") {
            setConfirmModalInfo({
                action: action,
                title: i18n.t("translation.connections.confirmationModal.delete.title"),
                message: i18n.t("translation.connections.confirmationModal.delete.message"),
                whatsAppId: whatsAppId,
            });
        }
        setConfirmModalOpen(true);
    };

    const handleSubmitConfirmationModal = async () => {
        if (!tenantId) return;
        if (confirmModalInfo.action === "disconnect") {
            try {
                await axiosPrivate.delete(`/${tenantId}/whatsappsession/${confirmModalInfo.whatsAppId}`);
            } catch (err) {
                toastError(err);
            }
        }

        if (confirmModalInfo.action === "delete") {
            try {
                await axiosPrivate.delete(`/${tenantId}/whatsapp/${confirmModalInfo.whatsAppId}`);
                toast.success(i18n.t("translation.connections.toasts.deleted"));
            } catch (err) {
                toastError(err);
            }
        }

        setConfirmModalInfo(confirmationModalInitialState);
    };

    const renderActionButtons = whatsApp => {
        return <>
            {whatsApp.status === "qrcode" && (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenQrModal(whatsApp)}
                >
                    {i18n.t("translation.connections.buttons.qrcode")}
                </Button>
            )}
            {whatsApp.status === "DISCONNECTED" && (
                <>
                    <Button
                        size="small"
                        variant="outlined"
                        className={classes.spaceRight}
                        onClick={() => handleStartWhatsAppSession(whatsApp.id)}
                    >
                        {i18n.t("translation.connections.buttons.tryAgain")}
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        className={classes.buttonColorError}
                        onClick={() => handleRequestNewQrCode(whatsApp.id)}
                    >
                        {i18n.t("translation.connections.buttons.newQr")}
                    </Button>
                </>
            )}
            {(whatsApp.status === "CONNECTED" ||
                whatsApp.status === "PAIRING" ||
                whatsApp.status === "TIMEOUT") && (
                    <Button
                        size="small"
                        variant="outlined"
                        className={classes.buttonColorError}
                        onClick={() => {
                            handleOpenConfirmationModal("disconnect", whatsApp.id);
                        }}
                    >
                        {i18n.t("translation.connections.buttons.disconnect")}
                    </Button>
                )}
            {whatsApp.status === "OPENING" && (
                <Button size="small" variant="outlined" disabled>
                    {i18n.t("translation.connections.buttons.connecting")}
                </Button>
            )}
        </>;
    };

    // const renderStatusBattery = whatsApp => {
    // 	return (
    // 		<div className={classes.customTableCell}>
    // 			{whatsApp.battery < 20 && (
    // 				<CustomTooltip title={whatsApp.battery}>
    // 					<BatteryCharging20Outlined color="secondary" />
    // 				</CustomTooltip>
    // 			)}
    // 			{whatsApp.battery < 30 && whatsApp.battery >= 20 && (
    // 				<CustomTooltip title={whatsApp.battery}>
    // 					<BatteryCharging30Outlined color="secondary" />
    // 				</CustomTooltip>
    // 			)}
    // 			{whatsApp.battery < 50 && whatsApp.battery >= 30 && (
    // 				<CustomTooltip title={whatsApp.battery}>
    // 					<BatteryCharging50Outlined style={{ color: orange[500] }} />
    // 				</CustomTooltip>
    // 			)}
    // 			{whatsApp.battery < 60 && whatsApp.battery >= 50 && (
    // 				<CustomTooltip title={whatsApp.battery}>
    // 					<BatteryCharging60Outlined style={{ color: orange[500] }} />
    // 				</CustomTooltip>
    // 			)}
    // 			{whatsApp.battery < 80 && whatsApp.battery >= 60 && (
    // 				<CustomTooltip title={whatsApp.battery}>
    // 					<BatteryCharging80Outlined style={{ color: green[500] }} />
    // 				</CustomTooltip>
    // 			)}
    // 			{whatsApp.battery < 90 && whatsApp.battery >= 80 && (
    // 				<CustomTooltip title={whatsApp.battery}>
    // 					<BatteryCharging90Outlined style={{ color: green[500] }} />
    // 				</CustomTooltip>
    // 			)}
    // 			{whatsApp.battery > 90 && (
    // 				<CustomTooltip title={whatsApp.battery}>
    // 					<BatteryChargingFullOutlined style={{ color: green[500] }} />
    // 				</CustomTooltip>
    // 			)}
    // 		</div>
    // 	);
    // };

    const maxConnections = getPlanValue("connectionsWhatsapp");
    const connectionsTotal = whatsApps?.length;

    const canCreateConnections = (connectionsTotal, maxConnections) => {
        if (connectionsTotal < maxConnections) {
            return true;
        }
    };

    const enableCreateButton = () => {
        if (canCreateConnections(connectionsTotal, maxConnections)) {
            handleOpenWhatsAppModal();
        } else {
            toast.error(i18n.t("translation.backendErrors.ERR_NO_PERMISSION_CREATE_CONNECTION_LIMIT"));
        }
    }

    // const restartBackend = async () => {
    // 	try {
    // 		await axiosPrivate.post("/server", {
    // 			cmd: "restart"
    // 		});
    // 		toast.success(i18n.t("translation.connections.toasts.restart"));
    // 	} catch (err) {
    // 		toastError(err);
    // 	}
    // };

    const getLastUpdate = (row) => {
        let status = row.getHistoryStatus;
        let job = importJobs.length > 0 && importJobs?.find(item => item?.whatsapp === row.id)
        let result = status === 'running'
            ? (<>
                <Tooltip arrow title={i18n.t("translation.connections.importing")}>
                    <div className={classes.alignCenter}>
                        <CloudSync color="success" className={classes.spaceRight} />{`${job?.this || 0}/${job?.all || 0}`}
                    </div>
                </Tooltip>

            </>)
            : format(parseISO(row.updatedAt), "dd/MM/yy HH:mm")
        return result
    }

    const columns = useMemo(() => [
        {
            field: "id",
            headerName: i18n.t("translation.variables.table.id"),
            flex: 0.15,
            minWidth: 30,
            type: 'number',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "name",
            headerName: i18n.t("translation.variables.table.name"),
            flex: 0.7,
            minWidth: 160,
            type: 'string',
            headerAlign: 'left',
            align: 'left'
        },
        {
            field: "number",
            headerName: i18n.t("translation.variables.table.number"),
            flex: 0.3,
            minWidth: 60,
            type: 'string',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "status",
            headerName: i18n.t("translation.variables.table.status.title"),
            flex: 0.2,
            minWidth: 45,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => <StatusTooltip whatsApp={params.row.whatsapp} />
        },
        {
            field: "session",
            headerName: i18n.t("translation.variables.table.session"),
            flex: 0.5,
            minWidth: 310,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <Can
                    role={userTenant?.role}
                    perform="connections:delete"
                    yes={() => renderActionButtons(params.row.whatsapp)}
                />
            )
        },
        {
            field: "lastUpdate",
            headerName: i18n.t("translation.variables.table.lastUpdate"),
            flex: 0.3,
            minWidth: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => getLastUpdate(params.row)
        },
        {
            field: "isDefault",
            headerName: i18n.t("translation.variables.table.default"),
            flex: 0.2,
            minWidth: 45,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => params.row.isDefault && <CheckCircle color="success" className={classes.defaultConnectionIcon} />
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.4,
            minWidth: 120,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <>
                    <Tooltip title={i18n.t("translation.connections.buttons.edit")}>
                        <IconButton
                            size="small"
                            color="primary"
                            className={classes.actionButtons}
                            onClick={() => handleEditWhatsApp(params.row.whatsapp)}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                    <Can
                        role={userTenant?.role}
                        perform="connections:import"
                        yes={() => (
                            <>
                                <Tooltip title={i18n.t("translation.contacts.confirmationModal.import.title")}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        className={classes.actionButtons}
                                        onClick={() => handleImportContacts(params.row.id)}
                                    >
                                        <ContactsOutlined />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    />
                    <Can
                        role={userTenant?.role}
                        perform="connections:reset"
                        yes={() => (
                            <>
                                <Tooltip title={i18n.t("translation.connections.buttons.reset")}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        className={classes.actionButtons}
                                        onClick={() => handleRestartWhatsAppSession(params.row.id)}
                                    >
                                        <RefreshOutlined />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    />
                    <Can
                        role={userTenant?.role}
                        perform="connections:delete"
                        yes={() => (
                            <>
                                <IconButton
                                    size="small"
                                    className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                    onClick={e => {
                                        handleOpenConfirmationModal("delete", params.row.id);
                                    }}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            </>
                        )}
                    />
                </>
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [whatsApps, loading, importJobs]);


    // Handle Channel actions
    const handleWhatsAppImportMessages = (data) => {
        if (data.action === "update") {
            // Create a copy of the current importJobs array
            const job = data.job;
            const jobs = [...importJobs];

            // Find the index of the existing WhatsApp object with the same value
            const existingIndex = jobs?.findIndex(item => item?.whatsapp === job?.whatsapp);
            existingIndex !== -1
                ? jobs[existingIndex] = job
                : jobs.push(job);

            // Update the state with the modified importJobs array
            setImportJobs(jobs);
        }
    }
    useSocketListener(`channel:whatsapp:import-messages`, (data) => handleWhatsAppImportMessages(data))

    // useEffect(() => {
    //     if (!importJobs) return;
    //     console.log("IMPORT JOB UPDATE", importJobs)
    // }, [importJobs])

    return (
        <MainContainer>
            <ConfirmationModal
                title={confirmModalInfo.title}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={handleSubmitConfirmationModal}
            >
                {confirmModalInfo.message}
            </ConfirmationModal>
            <QrcodeModal
                open={qrModalOpen}
                onClose={handleCloseQrModal}
                whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
            />
            <WhatsAppModal
                open={whatsAppModalOpen}
                onClose={handleCloseWhatsAppModal}
                whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
            />
            <MainHeader>
                <Title>
                    {i18n.t("translation.connections.title")}
                    <TutorialModalButton content="connections" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={enableCreateButton}
                    >
                        {i18n.t("translation.connections.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid item sx={{
                            width: "100%"
                        }}>
                            {rows && <DataGrid
                                className={classes.gridPaper}
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                getRowHeight={() => 'auto'}
                                pageSize={20}
                                getRowId={row => row.id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                            />}

                        </Grid>
                    </div>
                </div>
            </Paper>
        </MainContainer>
    );
};

export default Connections;
