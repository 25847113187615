const rules = {
    user: {
        static: [
            //Scheduled Messages
            "schedule-page:add:message",
            "schedule-page:delete:message:self",
            "schedule-page:edit:message:self",
            //Support
            "support:user",
            //Tickets
            "ticket-options:transfer",
            "ticket-options:closed",
            "ticket-options:blockCampaigns",
            "ticket-options:resolveWithNoFarewell",
            //View Menu
            "drawer-items:tickets",
        ],
    },

    supervisor: {
        static: [
            //Chat Internal
            "chatInternal-page:delete",
            //Contacts
            "contacts-page:view:contactEmail",
            "contacts-page:view:contactNumber",
            //Contact Groups
            "contactGroups-page:edit:group",
            "contactGroups-page:create:group",
            //Reports
            "reports-page:download:campaignData",
            "reports-page:view:campaigns",
            "reports-page:view:teams",
            //Scheduled Messages
            "schedule-page:add:message",
            "schedule-page:delete:message:self",
            "schedule-page:delete:message:all",
            "schedule-page:edit:message:self",
            "schedule-page:edit:message:all",
            //Support
            "support:supervisor",
            "support:user",
            //Tickets
            "ticket-manager:show:all",
            "ticket-options:closed",
            "ticket-options:transfer",
            "ticket-options:priority",
            "ticket-options:transfer:connection",
            "ticket-options:resolveWithNoFarewell",
            "ticket-options:blockCampaigns",
            //User Modal
            "user-modal:edit:queue",
            "user-modal:edit:user",
            "user-modal:edit:status",
            //View Menu
            "drawer-items:campaign",
            "drawer-items:dashboard",
            "drawer-items:management",
            "drawer-items:reports",
            "drawer-items:schedule",
            "drawer-items:tags",
            "drawer-items:tickets",
            "drawer-items:supervisor",
        ],
    },

    finance: {
        static: [
            //Reports
            "reports-page:view:campaigns",
            "reports-page:view:teams",
            //View Menu
            "drawer-items:dashboard",
            "drawer-items:finance",
            "drawer-items:management",
            "drawer-items:reports",
        ],
    },

    dashboard: {
        static: [
            //Reports
            "reports-page:view:campaigns",
            "reports-page:view:teams",
            //View Menu
            "drawer-items:dashboard",
            "drawer-items:management",
            "drawer-items:reports",
        ],
    },

    admin: {
        static: [
            //Bots
            "bot:delete",
            //Chat Internal
            "chatInternal-page:delete",
            //Connections
            "connections:delete",
            "connections:import",
            "connections:reset",
            //Contacts
            "contacts-page:delete:contact",
            "contacts-page:export:contact",
            "contacts-page:import:contact",
            "contacts-page:view:contactEmail",
            "contacts-page:view:contactNumber",
            //Contact Groups
            "contactGroups-page:delete:group",
            "contactGroups-page:edit:group",
            "contactGroups-page:create:group",
            //Queus
            "queues:duplicate",
            "queues:delete",
            //Reports
            "reports-page:download:campaignData",
            "reports-page:view:campaigns",
            "reports-page:view:teams",
            //Scheduled Messages
            "schedule-page:add:message",
            "schedule-page:delete:message:self",
            "schedule-page:delete:message:all",
            "schedule-page:edit:message:self",
            "schedule-page:edit:message:all",
            //Settings
            "settings-dev-items:view",
            "settings-super-items:view",
            //Tags
            "tags-page:add:tag",
            "tags-page:delete:tag",
            "tags-page:edit:tag",
            //Tickets
            "ticket-manager:show:all",
            "ticket-options:closed",
            "ticket-options:delete",
            "ticket-options:transfer",
            "ticket-options:priority",
            "ticket-options:transfer:connection",
            "ticket-options:signMessage",
            "ticket-options:resolveWithNoFarewell",
            "ticket-options:blockCampaigns",
            //User Modal
            "user-modal:edit:admin",
            "user-modal:edit:connection",
            "user-modal:edit:profile",
            "user-modal:edit:queue",
            "user-modal:edit:status",
            "user-modal:edit:user",
            "user-modal:delete:admin",
            "user-modal:delete:super",
            "user-modal:delete:user",
            //View Menu
            "drawer-items:admin",
            "drawer-items:bots",
            "drawer-items:campaign",
            "drawer-items:connections",
            "drawer-items:dashboard",
            "drawer-items:finance",
            "drawer-items:management",
            "drawer-items:queues",
            "drawer-items:reports",
            "drawer-items:schedule",
            "drawer-items:settings",
            "drawer-items:supervisor",
            "drawer-items:tags",
            "drawer-items:tickets",
        ],
    },

    super: {
        static: [
            //Bots
            "bot:delete",
            //Chat Internal
            "chatInternal-page:delete",
            //Connections
            "connections:delete",
            "connections:import",
            "connections:reset",
            //Contacts
            "contacts-page:delete:contact",
            "contacts-page:export:contact",
            "contacts-page:import:contact",
            "contacts-page:view:contactEmail",
            "contacts-page:view:contactNumber",
            //Contact Groups
            "contactGroups-page:delete:group",
            "contactGroups-page:edit:group",
            "contactGroups-page:create:group",
            //Queus
            "queues:duplicate",
            "queues:delete",
            //Reports
            "reports-page:download:campaignData",
            "reports-page:view:campaigns",
            "reports-page:view:teams",
            //Scheduled Messages
            "schedule-page:add:message",
            "schedule-page:delete:message:self",
            "schedule-page:delete:message:all",
            "schedule-page:edit:message:self",
            "schedule-page:edit:message:all",
            //Settings
            "settings-dev-items:view",
            "settings-super-items:edit",
            "settings-super-items:view",
            //Tags
            "tags-page:add:tag",
            "tags-page:delete:tag",
            "tags-page:edit:tag",
            //Tenant Modal
            "tenant-modal:delete:tenant",
            "tenant-modal:disable:tenant",
            "tenant-modal:edit:status",
            //Tickets
            "ticket-manager:show:all",
            "ticket-options:closed",
            "ticket-options:delete",
            "ticket-options:priority",
            "ticket-options:transfer",
            "ticket-options:transfer:connection",
            "ticket-options:signMessage",
            "ticket-options:resolveWithNoFarewell",
            "ticket-options:blockCampaigns",
            //User Modal
            "user-modal:delete:admin",
            "user-modal:delete:super",
            "user-modal:delete:user",
            "user-modal:edit:admin",
            "user-modal:edit:connection",
            "user-modal:edit:profile",
            "user-modal:edit:queue",
            "user-modal:edit:status",
            "user-modal:edit:super",
            "user-modal:edit:user",
            //View Menu
            "drawer-items:admin",
            "drawer-items:bots",
            "drawer-items:campaign",
            "drawer-items:connections",
            "drawer-items:dashboard",
            "drawer-items:finance",
            "drawer-items:management",
            "drawer-items:queues",
            "drawer-items:reports",
            "drawer-items:schedule",
            "drawer-items:super",
            "drawer-items:supervisor",
            "drawer-items:settings",
            "drawer-items:tags",
            "drawer-items:tickets",
        ]
    }
};

export default rules;
