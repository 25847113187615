import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
    Error,
    ExpandLess,
    ExpandMore,
} from "@mui/icons-material";
import {
    Badge,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    Activity,
    BarChart3,
    BarChart3Icon,
    BookOpenText,
    Bot,
    Cable,
    CalendarCheck,
    CircleUser,
    Command,
    Contact,
    CreditCard,
    Megaphone,
    MessageCircle,
    MessagesSquare,
    MonitorSmartphone,
    Network,
    PieChart,
    Receipt,
    Settings,
    Settings2,
    Tags,
    User,
    Users,
    UsersRound,
    Zap,
    Printer
} from "lucide-react";

import { Can } from "../components/Can";
import { i18n } from "../translate/i18n";

import NotificationFavicon from "../components/NotificationFavicon";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import useSettings from "../hooks/useSettings";
import useTenant from "../hooks/useTenant";

function ListItemLink(props) {
    const { icon, primary, to, className } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink
                    to={to}
                    ref={ref}
                    {...itemProps}
                    style={{ color: "inherit" }}
                />
            )),
        [to]
    );

    return (
        <li>
            <ListItem
                component={renderLink}
                className={className}
                sx={{ opacity: 30 }}
            >
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText
                    primary={primary}
                    sx={{
                        color: "GrayText",
                    }}
                />
            </ListItem>
        </li>
    );
}

const useStyles = makeStyles((theme) => ({
    alert: {
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.error.main}`,
        textAlign: "center",
        margin: theme.spacing(1, 1.5),
        padding: theme.spacing(0.5),
        whiteSpace: "normal",
    },
    error: {
        color: theme.palette.error.main,
    },
    link: {
        cursor: "pointer",
    },
    wrap: {
        wordWrap: "break-word !important",
        maxWidth: "inherit",
    },
}));

const MainListItems = (props) => {
    const classes = useStyles();
    const { drawerClose } = props;

    // Submenus
    const [alertSubmenu, toggleAlertSubmenu] = useState(false);
    const [reportSubmenu, toggleReportSubmenu] = useState(false);
    const [financeSubmenu, toggleFinanceSubmenu] = useState(false);
    const [contactsSubmenu, toggleContactsSubmenu] = useState(false);
    const [settingsSubmenu, toggleSettingsSubmenu] = useState(false);

    // Contexts
    const { whatsApps } = useContext(WhatsAppsContext);
    const { settings, getSettingValue } = useSettings();
    const { tenant, userTenant } = useTenant();

    // States
    const [connectionWarning, setConnectionWarning] = useState(false);
    const [numberOfConnections, setNumberOfConnections] = useState(0);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps?.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                    setNumberOfConnections(offlineWhats.length);
                } else {
                    setConnectionWarning(false);
                    setNumberOfConnections(0);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps, tenant]);

    useEffect(() => {
        if (!connectionWarning) return;
        NotificationFavicon({ content: "warning", state: connectionWarning });
    }, [connectionWarning]);

    return (
        <div onClick={drawerClose}>
            <Can
                role={userTenant?.role}
                perform="drawer-items:tickets"
                yes={() => (
                    <>
                        <ListItemLink
                            to={`/${tenant.slug}/tickets`}
                            primary={i18n.t(
                                "translation.mainDrawer.listItems.tickets"
                            )}
                            icon={<MessageCircle />}
                        />
                        <ListItemLink
                            to={`/${tenant.slug}/chats`}
                            primary={i18n.t(
                                "translation.mainDrawer.listItems.chats"
                            )}
                            icon={<MessagesSquare />}
                        />
                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:schedule"
                            yes={() => (
                                <>
                                    <ListItemLink
                                        to={`/${tenant.slug}/scheduledMessages`}
                                        primary={i18n.t(
                                            "translation.mainDrawer.listItems.scheduledMessages"
                                        )}
                                        icon={<CalendarCheck />}
                                    />
                                </>
                            )}
                        />

                        <ListItem
                            onClick={() =>
                                toggleContactsSubmenu((prev) => !prev)
                            }
                            className={classes.link}
                        >
                            <ListItemIcon>
                                <Contact/>
                            </ListItemIcon>
                            <ListItemText
                                primary={i18n.t(
                                    "translation.mainDrawer.listItems.contactsToggle"
                                )}
                            />
                            {contactsSubmenu ? (
                                <ExpandLess color="primary" />
                            ) : (
                                <ExpandMore color="primary" />
                            )}
                        </ListItem>

                        <Collapse
                            in={contactsSubmenu}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding sx={{ pl: 2 }}>
                                <ListItemLink
                                    to={`/${tenant.slug}/contacts`}
                                    primary={i18n.t(
                                        "translation.mainDrawer.listItems.contacts"
                                    )}
                                    icon={
                                        <User
                                            fontSize="small"

                                        />
                                    }
                                />
                                <ListItemLink
                                    to={`/${tenant.slug}/contactGroups`}
                                    primary={i18n.t(
                                        "translation.mainDrawer.listItems.contactGroups"
                                    )}
                                    icon={
                                        <UsersRound
                                            fontSize="small"
                                        />
                                    }
                                />
                            </List>
                        </Collapse>

                        <ListItemLink
                            to={`/${tenant.slug}/wiki`}
                            primary={i18n.t(
                                "translation.mainDrawer.listItems.Wiki"
                            )}
                            icon={<BookOpenText />}
                        />
                        <ListItemLink
                            to={`/${tenant.slug}/quickAnswers`}
                            primary={i18n.t(
                                "translation.mainDrawer.listItems.quickAnswers"
                            )}
                            icon={<Zap />}
                        />
                    </>
                )}
            />
            {connectionWarning && (
                <>
                    <ListItem
                        onClick={() => toggleAlertSubmenu((prev) => !prev)}
                        className={classes.link}
                    >
                        <ListItemIcon>
                            <Error color="error" />
                        </ListItemIcon>
                        <ListItemText
                            primary={i18n.t(
                                "translation.mainDrawer.alert.title"
                            )}
                            className={classes.error}
                        />
                        {alertSubmenu ? (
                            <ExpandLess color="error" />
                        ) : (
                            <ExpandMore color="error" />
                        )}
                    </ListItem>

                    <Collapse in={alertSubmenu} timeout="auto" unmountOnExit>
                        <Typography
                            variant="inherit"
                            className={[classes.error, classes.alert].join(" ")}
                        >
                            {i18n.t(
                                "translation.mainDrawer.alert.description",
                                {
                                    count: numberOfConnections,
                                }
                            )}
                        </Typography>
                    </Collapse>
                </>
            )}
            <Can
                role={userTenant?.role}
                perform="drawer-items:management"
                yes={() => (
                    <>
                        <Divider />
                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:dashboard"
                            yes={() => (
                                <>
                                    <ListItemLink
                                        to={`/${tenant.slug}/`}
                                        primary={i18n.t(
                                            "translation.mainDrawer.listItems.dashboard"
                                        )}
                                        icon={<PieChart />}
                                    />
                                </>
                            )}
                        />
                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:reports"
                            yes={() => (
                                <>
                                    <ListItem
                                        onClick={() =>
                                            toggleReportSubmenu((prev) => !prev)
                                        }
                                        className={classes.link}
                                    >
                                        <ListItemIcon>
                                            <BarChart3 />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={i18n.t(
                                                "translation.mainDrawer.listItems.reports"
                                            )}
                                        />
                                        {reportSubmenu ? (
                                            <ExpandLess color="primary" />
                                        ) : (
                                            <ExpandMore color="primary" />
                                        )}
                                    </ListItem>

                                    <Collapse
                                        in={reportSubmenu}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List
                                            component="div"
                                            disablePadding
                                            sx={{ pl: 2 }}
                                        >
                                            {/* <ListItemLink
                                                to={`/${tenant.slug}/reports/campaigns`}
                                                primary={i18n.t(
                                                    "translation.reports.report.campaigns.title"
                                                )}
                                                icon={
                                                    <Megaphone fontSize="small" />
                                                }
                                            /> */}
                                            <Can
                                                role={userTenant?.role}
                                                perform="drawer-items:tags"
                                                yes={() => (
                                                    <>
                                                        <ListItemLink
                                                            to={`/${tenant.slug}/tags`}
                                                            primary={i18n.t(
                                                                "translation.mainDrawer.listItems.tags"
                                                            )}
                                                            icon={
                                                                <Tags fontSize="small" />
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                            <ListItemLink
                                                to={`/${tenant.slug}/reports/teams`}
                                                primary={i18n.t(
                                                    "translation.reports.report.teams.title"
                                                )}
                                                icon={
                                                    <Activity fontSize="small" />
                                                }
                                            />
                                              <ListItemLink
                                                to={`/${tenant.slug}/reports/tickets`}
                                                primary={i18n.t(
                                                    "translation.reports.report.tickets.title"
                                                )}
                                                icon={
                                                    <Printer
                                                        fontSize="small"
                                                    />
                                                }
                                            />
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        />

                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:finance"
                            yes={() => (
                                <>
                                    <ListItem
                                        onClick={() =>
                                            toggleFinanceSubmenu(
                                                (prev) => !prev
                                            )
                                        }
                                        className={classes.link}
                                    >
                                        <ListItemIcon>
                                            <CreditCard />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={i18n.t(
                                                "translation.mainDrawer.listItems.finance"
                                            )}
                                        />
                                        {financeSubmenu ? (
                                            <ExpandLess color="primary" />
                                        ) : (
                                            <ExpandMore color="primary" />
                                        )}
                                    </ListItem>

                                    <Collapse
                                        in={financeSubmenu}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List
                                            component="div"
                                            disablePadding
                                            sx={{ pl: 2 }}
                                        >
                                            <ListItemLink
                                                to={`/${tenant.slug}/account`}
                                                primary={i18n.t(
                                                    "translation.mainDrawer.listItems.account"
                                                )}
                                                icon={
                                                    <CircleUser fontSize="small" />
                                                }
                                            />
                                            <ListItemLink
                                                to={`/${tenant.slug}/subscriptions`}
                                                primary={i18n.t(
                                                    "translation.mainDrawer.listItems.subscriptions"
                                                )}
                                                icon={
                                                    <BarChart3Icon fontSize="small" />
                                                }
                                            />
                                            <ListItemLink
                                                to={`/${tenant.slug}/invoices`}
                                                primary={i18n.t(
                                                    "translation.mainDrawer.listItems.invoices"
                                                )}
                                                icon={
                                                    <Receipt fontSize="small" />
                                                }
                                            />
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        />

                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:management"
                            yes={() => (
                                <>
                                    {settings &&
                                    settings.length > 0 &&
                                    getSettingValue("campaignCreation") ===
                                        "enabled" ? (
                                        <Can
                                            role={userTenant?.role}
                                            perform="drawer-items:campaign"
                                            yes={() => (
                                                <>
                                                    <ListItemLink
                                                        to={`/${tenant.slug}/campaign`}
                                                        primary={i18n.t(
                                                            "translation.mainDrawer.listItems.campaign"
                                                        )}
                                                        icon={<Megaphone />}
                                                    />
                                                </>
                                            )}
                                        />
                                    ) : (
                                        ""
                                    )}

                                    <Can
                                        role={userTenant?.role}
                                        perform="drawer-items:supervisor"
                                        yes={() => (
                                            <>
                                                <ListItemLink
                                                    to={`/${tenant.slug}/users`}
                                                    primary={i18n.t(
                                                        "translation.mainDrawer.listItems.users"
                                                    )}
                                                    icon={<Users />}
                                                />
                                                <Can
                                                    role={userTenant?.role}
                                                    perform="drawer-items:connections"
                                                    yes={() => (
                                                        <>
                                                            <ListItemLink
                                                                to={`/${tenant.slug}/connections`}
                                                                primary={i18n.t(
                                                                    "translation.mainDrawer.listItems.connections"
                                                                )}
                                                                icon={
                                                                    <Badge
                                                                        badgeContent={
                                                                            connectionWarning
                                                                                ? "!"
                                                                                : 0
                                                                        }
                                                                        color="error"
                                                                        overlap="rectangular"
                                                                    >
                                                                        <MonitorSmartphone />
                                                                    </Badge>
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <Can
                                                    role={userTenant?.role}
                                                    perform="drawer-items:queues"
                                                    yes={() => (
                                                        <>
                                                            <ListItemLink
                                                                to={`/${tenant.slug}/queues`}
                                                                primary={i18n.t(
                                                                    "translation.mainDrawer.listItems.queues"
                                                                )}
                                                                icon={
                                                                    <Network />
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <Can
                                                    role={userTenant?.role}
                                                    perform="drawer-items:bots"
                                                    yes={() => (
                                                        <>
                                                            <ListItemLink
                                                                to={`/${tenant.slug}/bots`}
                                                                primary={i18n.t(
                                                                    "translation.mainDrawer.listItems.bots"
                                                                )}
                                                                icon={<Bot />}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </>
                                        )}
                                    />
                                </>
                            )}
                        />
                    </>
                )}
            />
            <Can
                role={userTenant?.role}
                perform="drawer-items:admin"
                yes={() => (
                    <>
                        <ListItem
                            onClick={() =>
                                toggleSettingsSubmenu((prev) => !prev)
                            }
                            className={classes.link}
                        >
                            <ListItemIcon>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText
                                primary={i18n.t(
                                    "translation.mainDrawer.listItems.application"
                                )}
                            />
                            {settingsSubmenu ? (
                                <ExpandLess color="primary" />
                            ) : (
                                <ExpandMore color="primary" />
                            )}
                        </ListItem>

                        <Collapse
                            in={settingsSubmenu}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding sx={{ pl: 2 }}>
                                <ListItemLink
                                    to={`/${tenant.slug}/api`}
                                    primary={i18n.t(
                                        "translation.mainDrawer.listItems.api"
                                    )}
                                    icon={<Cable fontSize="small" />}
                                />
                                <ListItemLink
                                    to={`/${tenant.slug}/widget`}
                                    primary={i18n.t(
                                        "translation.mainDrawer.listItems.widget"
                                    )}
                                    icon={<Command fontSize="small" />}
                                />
                                <Can
                                    role={userTenant?.role}
                                    perform="drawer-items:settings"
                                    yes={() => (
                                        <>
                                            <ListItemLink
                                                to={`/${tenant.slug}/settings`}
                                                primary={i18n.t(
                                                    "translation.mainDrawer.listItems.settings"
                                                )}
                                                icon={
                                                    <Settings2 fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </List>
                        </Collapse>
                    </>
                )}
            />
        </div>
    );
};

export default MainListItems;
