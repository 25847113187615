import React, { useEffect, useState } from 'react';

import { Button, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import appVars from "../../../package.json";
import toastError from '../../errors/toastError';
import wordpressData from "../../services/wordpress";
import app from "../../../package.json"
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        color: '#fafafa',
    },
    h3: {
        color: '#fafafa',
        fontWeight: 500
    },
}));

export default function Changelog() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);
    // let content = i18n.t("translation.changelog.content").split('\n')

    // https://bits.bio/wp-json/wp/v2/posts?categories=11&per_page=1

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchData = async () => {
            try {
                await wordpressData().then((data) => {
                    isMounted && setContent(data.json[0])
                });
            } catch (err) {
                isMounted && toastError(err, "", "Error while trying to get bot info on Bot Modal.");
            }
            isMounted && setLoading(false);
        };
        fetchData();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToChangelog = () => {
        window.open(appVars.changelog.url, '_blank');
    }

    return (
        <div className={classes.center}>
            {!loading && (<>
                <Typography variant="body1" color="textSecondary" align="center">
                    <span className={classes.h3}>{i18n.t("translation.changelog.title")} {
                    app.version
                    /*content?.title?.rendered.split("v.")[1]*/}</span>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: content?.excerpt?.rendered }} />
            </>)}
            <Button
                variant="outlined"
                size="small"
                color="primary"
                align="center"
                onClick={goToChangelog}
            >
                {i18n.t("translation.changelog.button")}
            </Button>
        </div>
    );
}
