import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { AppsRounded } from "@mui/icons-material";
import {
    AppBar,
    Avatar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import logoLight from "../assets/logo_color.svg";
// import logoDark from "../assets/logo_dark.png";

import BackdropLoading from "../components/BackdropLoading";
import { Can } from "../components/Can";
import NotificationsPopOver from "../components/NotificationsPopOver";
import ChatPopover from "../components/NotificationsPopOver/ChatPopover";
import UserModal from "../components/UserModal";

import appVars from "../../package.json";
import toastError from "../errors/toastError";
import avatar from "../services/avatar";
import { i18n } from "../translate/i18n";
import MainListItems from "./MainListItems";

import { useNavigate } from "react-router-dom";
import { NotificationsVolume } from "../components/NotificationsVolume";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useTenant from "../hooks/useTenant";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    // appBar: {
    //   zIndex: theme.zIndex.drawer + 1,
    //   transition: theme.transitions.create(["width", "margin"], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    // },
    appBarSpacer: {
        minHeight: theme.spacing(6),
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flex: 1,
        padding: theme.spacing(1, 2, 2, 2),
        overflow: "auto",
        ...theme.scrollbarStyles,
    },
    // drawerPaper: {
    //   // position: "relative",
    //   whiteSpace: "nowrap",
    //   width: "100%",
    //   maxWidth: drawerWidth,
    //   transition: theme.transitions.create("maxWidth", {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   overflowX: "hidden",
    // },
    // drawerPaperClose: {
    //   overflowX: "hidden",
    //   width: "100%",
    //   maxWidth: theme.spacing(7),
    //   [theme.breakpoints.up("sm")]: {
    //     maxWidth: theme.spacing(8),
    //   },
    //   transition: theme.transitions.create("maxWidth", {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    // },

    drawerItems: {
        height: "unset",
        maxHeight: `calc(100% - ${theme.spacing(6)} - ${theme.spacing(4)})`,
        borderRight: "none",
        margin: `calc(${theme.spacing(6)} + ${theme.spacing(
            2
        )}) ${theme.spacing(2)} 0`,
        border: "1px solid rgba(0, 0, 0, 0.05)",
        borderRadius: theme.spacing(2),
    },
    drawerMobile: {
        "& .MuiDrawer-paper": {
            paddingTop: theme.spacing(6),
            boxSizing: "border-box",
            width: drawerWidth,
        },
    },
    drawerScrollbar: {
        overflowX: "hidden",
        ...theme.scrollbarStyles,
    },

    logo: {
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: "10px",
        marginTop:"5px"
        // [theme.breakpoints.down("sm")]: {
        //   marginLeft: "unset",
        // },
    },
    logoImg: {
        width: "100px",
        transform: "translate(0px, -2px)",
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        ...theme.scrollbarStyles,
    },
    root: {
        display: "flex",
        height: "100vh",
    },
    tenantSelector: {
        maxWidth: "260px",
        minWidth: "200px",
        maxHeight: theme.spacing(22),
        overflow: "auto",
        ...theme.scrollbarStyles,
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        padding: theme.spacing(0, 2),
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    version: {
        opacity: "0.5",
        fontSize: theme.spacing(1.5),
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
}));

const StyledAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: /*theme.zIndex.drawer + */1,
    background: 'white',
    color: 'inherit',
    boxShadow: 'none',
    // background: 'linear-gradient(45deg, #380055, #3e0b97, #a702c9)',
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const openedMixin = (theme) => ({
    zIndex: "unset", // TODO: fix react-modal-image incompatibility
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    zIndex: "unset", // TODO: fix react-modal-image incompatibility
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const StyledDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    // display: { xs: 'none', sm: 'block' },
    margin: "0 8px",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
    [theme.breakpoints.down("sm")]: {
        "& ul": {
            paddingTop: theme.spacing(8),
        },
    },
}));

const LoggedInLayout = ({ children }) => {
    const classes = useStyles();
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [volume, setVolume] = useState(localStorage.getItem("volume") || 1);
    const { handleLogout, loading, user } = useAuth();
    const { tenant, tenantId, userTenant } = useTenant();

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        if (document.body.offsetWidth > 600) {
            const fetchDrawerState = async () => {
                try {
                    const { data } = await axiosPrivate.get(
                        `/${tenantId}/settings`
                    );

                    const settingIndex = data.filter(
                        (s) => s.key === "sideMenu"
                    );

                    isMounted &&
                        setDrawerOpen(
                            settingIndex[0].value === "disabled" ? false : true
                        );
                } catch (err) {
                    isMounted && setDrawerOpen(true);
                    toastError(err);
                }
            };
            if (tenantId) fetchDrawerState();
        }

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleOpenUserModal = () => {
        setUserModalOpen(true);
        handleCloseMenu();
    };

    const handleClickSuper = (location) => {
        window.open(`/${tenant.slug}/${location}`, "_self");
    };

    const handleClickLogout = () => {
        handleCloseMenu();
        handleLogout();
    };

    const drawerClose = () => {
        if (document.body.offsetWidth < 600) {
            setDrawerOpen(false);
        }
    };

    const loggedInPage = (role) => {
        switch (role) {
            case "super":
                // return "/super";
                return "/tenants";

            case "finance":
                return "/invoices";

            case "admin":
            case "dashboard":
            case "tenants":
                return "/";

            default:
                return "/tickets";
        }
    };

    if (loading) {
        return <BackdropLoading />;
    }

    const container = window.document.body;
    return (
        <div className={classes.root}>
            <UserModal
                open={userModalOpen}
                onClose={() => setUserModalOpen(false)}
                userId={user?.id}
            />

            <StyledAppBar position="absolute">
                <Toolbar variant="dense" className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={clsx(classes.menuButton)}
                        size="large"
                    >
                        <AppsRounded />
                    </IconButton>

                    <div className={classes.logo}>
                        <img
                            className={classes.logoImg}
                            src={logoLight}
                            alt={appVars.appName}
                        />
                    </div>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        <span className={classes.version}>
                            {" v"}
                            {appVars.version}
                            {process.env.NODE_ENV === "development"
                                ? " (dev)"
                                : ""}
                        </span>
                    </Typography>

                    <NotificationsVolume
                        volume={volume}
                        setVolume={setVolume}
                    />


                    {user.id &&
                        userTenant?.role !== "dashboard" &&
                        userTenant?.role !== "finance" && (
                            <NotificationsPopOver volume={volume} />
                        )}
                    <ChatPopover />

                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            size="large"
                        >
                            <Avatar
                                src={avatar(user.email)}
                                alt={user.name}
                                variant="rounded"
                                sx={{ width: 24, height: 24 }}
                            />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={menuOpen}
                            onClose={handleCloseMenu}
                        >
                            {user?.userTenant &&
                                user?.userTenant.length > 0 && (
                                    <div className={classes.tenantSelector}>
                                        {user.userTenant.map((tenant) => (
                                            <MenuItem
                                                onClick={() =>
                                                    navigate(`/${tenant.tenant.slug}${loggedInPage(tenant.role)}`)
                                                }
                                                key={tenant.tenantId}
                                                sx={{ minWidth: "160px" }}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        src={avatar(
                                                            tenant.tenant.email,
                                                            "IconHorse"
                                                        )}
                                                        alt={tenant.tenant.name}
                                                        variant="rounded"
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography noWrap>
                                                            {tenant.tenant.name}
                                                        </Typography>
                                                    }
                                                    secondary={i18n.t(
                                                        `userModal.form.profile.role.${tenant.role}`
                                                    )}
                                                />
                                            </MenuItem>
                                        ))}
                                    </div>
                                )}
                            <Divider />
                            <MenuItem onClick={handleOpenUserModal}>
                                {i18n.t(
                                    "translation.mainDrawer.appBar.user.profile"
                                )}
                            </MenuItem>
                            <MenuItem onClick={handleClickLogout}>
                                {i18n.t(
                                    "translation.mainDrawer.appBar.user.logout"
                                )}
                            </MenuItem>
                            <Can
                                role={userTenant?.role}
                                perform="drawer-items:super"
                                yes={() => (
                                    <>
                                        <Divider />
                                        <MenuItem
                                            onClick={() =>
                                                handleClickSuper("super")
                                            }
                                        >
                                            {i18n.t(
                                                "translation.mainDrawer.listItems.super.menu"
                                            )}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                handleClickSuper("tenants")
                                            }
                                        >
                                            {i18n.t(
                                                "translation.mainDrawer.listItems.super.tenants"
                                            )}
                                        </MenuItem>
                                    </>
                                )}
                            />
                        </Menu>
                    </div>
                </Toolbar>
            </StyledAppBar>

            <StyledDrawer
                variant="permanent"
                open={drawerOpen}
                color="primary"
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
                PaperProps={{
                    elevation: 1,
                    className: classes.drawerItems,
                }}
            >
                <List className={classes.drawerScrollbar}>
                    <MainListItems drawerClose={drawerClose} />
                </List>
            </StyledDrawer>

            <Drawer
                container={container}
                variant="temporary"
                open={drawerOpen}
                onClose={() => setDrawerOpen(!drawerOpen)}
                color="primary"
                ModalProps={{ keepMounted: true }}
                className={classes.drawerMobile}
                sx={{
                    display: { xs: "block", sm: "none" },
                }}
            >
                <List className={classes.drawerScrollbar}>
                    <MainListItems drawerClose={drawerClose} />
                </List>
            </Drawer>

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {children || ""}
            </main>
        </div>
    );
};

export default LoggedInLayout;
