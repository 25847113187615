import React, { useCallback, useEffect, /*useMemo,*/ useState } from "react";
import Routes from "./routes";

import CssBaseline from "@mui/material/CssBaseline";
import {
    StyledEngineProvider,
    ThemeProvider,
    createTheme
} from "@mui/material/styles";

import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/index.css";
// import handleTheme from "./assets/styles/theme";

import { ptBR } from "@mui/material/locale";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { getFirebaseToken, onMessageListener } from "./services/firebase/firebaseInit";
import './global.css';

const App = () => {
    const [locale, setLocale] = useState();
    // const customStyles = {
    //   '.__react_modal_image__modal_container, .__react_modal_image__header': {
    //     backgroundColor: '#0437ff17!important',
    //     backdropFilter: 'blur(3px)',
    //   },
    //   '.__react_modal_image__header': {
    //     backgroundColor: '#0437ff17!important',
    //     boxShadow: '0px 0px 13px 13px #0437ff50',
    //   },
    // };

    // const theme = createTheme(adaptV4Theme({
    const theme = createTheme({
        scrollbarStyles: {
            "&::-webkit-scrollbar": {
                width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                backgroundColor: "rgba(0,0,0,0.3)",
                borderRadius: "8px"
            },
        },
        palette: {
            primary: {
                main: "#7c3aed",
                contrastText: "#fff",
            },
            badgesGroup: {
                main: "#f6b300",
            },
            badgesSingle: {
                main: "#8f31ac",
            },
            badgesWhatsApp: {
                main: "#44b700"
            },
            comment: {
                main: "#fffdbe"
            },
            danger: {
                main: "#fda4af"
            },
            error: {
                main: "rgb(251 113 133)",
                contrastText: "#fff",
            },
            secondary: {
                main: "#96E4CB",
                contrastText: "#456C6E",
            },
            success: {
                main: "#00574E",
                hover: "#4ff16a",
                contrastText: "#fff",
            },
            tertiary: {
                main: "#f50057",
                contrastText: "#fff",
            },
            warning: {
                main: "#fef9c3"
            }
        },

        typography: {

    fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  h1: {
    fontWeight: 700,
    fontSize: 35
  },
  h2: {
    fontWeight: 700,
    fontSize: 30
  },
  h3: {
    fontWeight: 700,
    fontSize: 25,
    lineHeight: 1.4,
  },
  h4: {
    fontWeight: 700,
    fontSize: 16
  },
  h5: {
    fontWeight: 700,
    fontSize: 14
  },
  h6: {
    fontSize: 15
  },
  body1: {
    fontSize: 14
  },
  body2: {
    fontSize: 14
  },
  button: {
    fontWeight: 600
  },
  caption: {
    fontSize: 13,
    textTransform: 'uppercase',
  },
  subtitle1: {
    fontSize: 14,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 15,
  },
  overline: {
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'uppercase'
  }
        },

        // overrides: {
        //   MuiCssBaseline: {
        //     '@global': customStyles,
        //   },
        // },

        components: {

            // MuiBackdrop: {
            // styleOverrides: {
            //   root: {
            //     backdropFilter: 'blur(2px)',

            //     '&.MuiBackdrop-invisible': {
            //       backgroundColor: 'transparent',
            //       backdropFilter: 'blur(2px)'
            //     }
            //   }
            // }
            // },
            MuiSlider: {
                styleOverrides: {
                  root: {
                    '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
                      transform: 'none'
                    },
                    '& .MuiSlider-valueLabel': {
                      borderRadius: 6,
                    //   background: colors.alpha.black[100],
                    //   color: colors.alpha.white[100]
                    }
                  }
                }
              },
            MuiTypography: {
                defaultProps: {
                  variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'div',
                    h4: 'div',
                    h5: 'div',
                    h6: 'div',
                    subtitle1: 'div',
                    subtitle2: 'div',
                    body1: 'div',
                    body2: 'div'
                  }
                },
                styleOverrides: {
                  gutterBottom: {
                    marginBottom: 4
                  },
                  paragraph: {
                    fontSize: 17,
                    lineHeight: 1.7
                  }
                }
              },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        // borderRadius: "15px!important"
                    },
                    rounded: {
                        "&:first-of-type": {
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15
                        },
                        "&:last-of-type": {
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15
                        },
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 10,
                        // boxShadow: "0px 10px 26px 0px rgb(4 47 255 / 35%)"
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#0437ff17", //blue
                        // backgroundColor: "#ffffff99", //white
                        backdropFilter: "blur(3px)"
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    container: {
                        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)",
                    },
                    paper: {
                        borderRadius: 20,
                        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)"
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        maxHeight: "calc(100vh - 181px)",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                            width: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                            backgroundColor: "rgba(0,0,0,0.3)",
                            borderRadius: "8px"
                        },
                    }
                }
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none'
                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: 'rgba(0,0,0,0.05)'
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    fullWidth: {
                        marginBottom: 16
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginBottom: 16
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: 46
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 16
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    outlined: {
                        border: '1px solid rgba(0, 0, 0, 0.05)'
                    },
                    rounded: {
                        borderRadius: 20,
                    },
                    elevation1: {
                        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.07)"
                    }
                }
            }
        }
        // }, locale));
    }, locale);

    const [i18nlocale] = useLocalStorage("i18nextLng", "pt-BR", true);

    // Call firebase notifications
    const [, setFirebaseToken] = useLocalStorage("firebaseToken", null, true);

    // Call firebase settings
    const getNotificationPermission = useCallback(async () => {
        await getFirebaseToken(setFirebaseToken);
        onMessageListener();
    }, [setFirebaseToken]);

    // // Call external theme
    // const theme = handleTheme(locale)

    useEffect(() => {
        if (i18nlocale) {
            const browserLocale = i18nlocale.substring(0, 5);
            if (browserLocale === "pt-BR" || browserLocale === "pt") {
                setLocale(ptBR);
            }
        }

        // Get registration for Firebase notifications
        getNotificationPermission()
    }, [i18nlocale, getNotificationPermission]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <Routes />
                </ CssBaseline>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
