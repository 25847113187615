import {
    Avatar,
    Button,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useMemo, useState } from "react";
import Title from "../../../../components/LayoutComponents/Title";
import MainContainer from "../../../../components/MainContainer";
import MainHeader from "../../../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../../../components/MainHeaderButtonsWrapper";
import { i18n } from "../../../../translate/i18n";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import MultiFieldLine from "../../../../components/FormComponents/MultiFieldLine";

import {
    CheckCircle,
    Launch,
    Pause,
    People,
    Warning,
} from "@mui/icons-material";
import toastError from "../../../../errors/toastError";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useTenant from "../../../../hooks/useTenant";

const useStyles = makeStyles((theme) => ({
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    root: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            flexWrap: "wrap",
        },
    },
    input: {
        width: 300,
        // marginBottom: theme.spacing(2)
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    minWidthColumns: {
        display: "flex",
        alignItems: "center",
        minWidth: "100%",
    },
}));

const filter = createFilterOptions({
    trim: true,
});

export function ReportTickets() {
    const now = new Date();
    const classes = useStyles();

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const { tenantId } = useTenant();
    const axiosPrivate = useAxiosPrivate();
    const [tickets, setTickets] = useState([]);
    const [ticketsCount, setTicketsCount] = useState(0);

    const [rows, setRows] = useState([
        {
            id: "",
            contactName: "",
        },
    ]);

    const columns = useMemo(
        () => [
            {
                field: "protocol",
                headerName: i18n.t("translation.variables.table.id"),
                align: "center",
                minWidth: 5,
            },
            {
                field: "contact.name",
                headerName: i18n.t("translation.variables.table.name"),
                flex: 1,
                minWidth: 60,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => (
                    <div className={classes.minWidthColumns}>
                        <Avatar
                            src={params.row.contact.profilePicUrl}
                            alt={params.row.contact.name}
                            variant="rounded"
                        />
                        <Typography
                            className={classes.contactName}
                            variant="inherit"
                        >
                            {params.row.contact.name}
                        </Typography>
                    </div>
                ),
            },

            {
                field: "queue",
                headerName: i18n.t("translation.variables.table.queue"),
                flex: 0.25,
                minWidth: 60,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => (
                    <Typography
                        variant="inherit"
                        style={{ color: params.row.queue?.color || "#7C7C7C" }}
                    >
                        {params.row.queue?.name ||
                            i18n.t("translation.ticketsList.items.queueless")}
                    </Typography>
                ),
            },

            {
                field: "status",
                headerName: i18n.t("translation.variables.table.status.title"),
                flex: 0.1,
                minWidth: 40,
                description: i18n.t(
                    "translation.invoices.table.descriptions.status"
                ),
                headerAlign: "center",
                align: "center",
                renderCell: (params) => (
                    <div className={classes.status}>
                        {params.row.status === "draft" && (
                            <Chip
                                size="small"
                                //icon={<EditOutlined />}
                                label={i18n.t(
                                    "translation.invoices.table.status.draft"
                                )}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {params.row.status === "open" && (
                            <Chip
                                size="small"
                                icon={<People />}
                                label={i18n.t(
                                    "translation.tickets.table.status.open"
                                )}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {params.row.status === "pending" && (
                            <Chip
                                size="small"
                                icon={<Warning />}
                                label={i18n.t(
                                    "translation.tickets.table.status.pending"
                                )}
                                color="error"
                                variant="outlined"
                            />
                        )}
                        {params.row.status === "waiting" && (
                            <Chip
                                size="small"
                                icon={<Pause />}
                                label={i18n.t(
                                    "translation.tickets.table.status.waiting"
                                )}
                                color="error"
                                variant="outlined"
                            />
                        )}
                        {params.row.status === "closed" && (
                            <Chip
                                size="small"
                                icon={<CheckCircle />}
                                label={i18n.t(
                                    "translation.tickets.table.status.closed"
                                )}
                                color="success"
                                variant="outlined"
                            />
                        )}
                    </div>
                ),
            },

            {
                field: "user",
                headerName: i18n.t("translation.variables.table.user"),
                flex: 0.2,
                minWidth: 40,
                headerAlign: "center",
                align: "center",
            },

            {
                field: "createdAt",
                headerName: i18n.t("translation.variables.table.createdAt"),
                flex: 0.2,
                minWidth: 40,
                headerAlign: "center",
                align: "center",
                renderCell: (params) =>
                    new Date(params.row.createdAt).toLocaleString(
                        i18n.t("translation.validation.locale"),
                        { dateStyle: "short" }
                    ),
            },
            {
                field: "actions",
                headerName: i18n.t("translation.variables.table.actions"),
                flex: 0.2,
                minWidth: 40,
                sortable: false,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => (
                    // params.row.owner?.id === user.id || showAll ? (
                    <>
                        <IconButton
                            size="small"
                            color="primary"
                            // onClick={() => window.open(
                            //     `${params.row?.extraInfo[params.row?.provider]?.invoice?.url}`,
                            //     "_blank"
                            // )}
                            // disabled={!params.row?.extraInfo[params.row?.provider]?.invoice?.url}
                        >
                            <Launch />
                        </IconButton>
                    </>
                ),
            },
        ], // eslint-disable-next-line react-hooks/exhaustive-deps
        [tickets]
    );

    useEffect(() => {
        if (!tickets) return;
        let dataGridRows = tickets.map((ticket) => ({
            id: ticket?.id,
            protocol: ticket?.protocol,
            user: ticket?.userTenant?.user?.name || "N/A",
            status: ticket?.status,
            createdAt: ticket?.createdAt,
            contact: ticket?.contact,
            queue: ticket?.queue,
        }));

        setRows(dataGridRows);
    }, [tickets]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        if (searchParam.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const fetchContacts = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(
                        `/${tenantId}/contacts`,
                        {
                            params: { searchParam },
                        }
                    );
                    isMounted && setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            }
        };

        fetchContacts();
        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, tenantId]);

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };
    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setSelectedContact(newValue);
        }
    };

    const renderOption = (props, option) => {
        if (option.number) {
            return (
                <li {...props}>
                    {option.name} - {option.number}
                </li>
            );
        } else {
           
        }
    };

    const renderOptionLabel = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    async function handleFilter() {
        const { data } = await axiosPrivate.post(`/${tenantId}/report/ticket`, {
            selectedContact,
            dateStart: document.getElementById("dateStart").value,
            dateEnd: document.getElementById("dateEnd").value,
        });
        setTickets(data.tickets);
        setTicketsCount(data.count);
    }
    return (
        <>
            <MainContainer>
                <MainHeader>
                    <Title>
                        {i18n.t("translation.reports.report.tickets.title")}
                    </Title>

                    <MainHeaderButtonsWrapper>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFilter}
                        >
                            Filtrar
                        </Button>
                    </MainHeaderButtonsWrapper>
                </MainHeader>
                <div>
                    {" "}
                    <MultiFieldLine>
                        <Autocomplete
                            options={options}
                            loading={loading}
                            className={classes.input}
                            size="small"
                            clearOnBlur
                            autoHighlight
                            freeSolo
                            clearOnEscape
                            getOptionLabel={renderOptionLabel}
                            renderOption={renderOption}
                            filterOptions={createAddContactOption}
                            onChange={(e, newValue) =>
                                handleSelectOption(e, newValue)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={i18n.t(
                                        "translation.newTicketModal.fieldLabel"
                                    )}
                                    variant="outlined"
                                    autoFocus
                                    // disabled={contactId? true :false}
                                    onChange={(e) =>
                                        setSearchParam(e.target.value)
                                    }
                                    onKeyPress={(e) => {
                                        if (loading || !selectedContact) return;
                                        else if (e.key === "Enter") {
                                            //handleSaveTicket(selectedContact.id);
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <TextField
                            ampm="false"
                            autoFocus
                            className={classes.textField}
                            defaultValue={
                                now.getFullYear() +
                                "-" +
                                ("0" + (now.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + (now.getDate() - 7)).slice(-2)
                            }
                            id="dateStart"
                            InputLabelProps={{ shrink: true }}
                            label={`${i18n.t(
                                "translation.dashboard.dateFilterModal.dateRange.start"
                            )}`}
                            size="small"
                            type="date"
                            variant="outlined"
                        />
                        <TextField
                            ampm="false"
                            className={classes.textField}
                            defaultValue={
                                now.getFullYear() +
                                "-" +
                                ("0" + (now.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + now.getDate()).slice(-2)
                            }
                            id="dateEnd"
                            InputLabelProps={{ shrink: true }}
                            label={`${i18n.t(
                                "translation.dashboard.dateFilterModal.dateRange.end"
                            )}`}
                            size="small"
                            type="date"
                            variant="outlined"
                        />
                    </MultiFieldLine>
                </div>
                <Paper className={classes.mainPaper} variant="outlined">
                    <div className={classes.root}>
                        <div className={classes.container}>
                            <Grid item sx={{ width: "100%" }}>
                                {rows && (
                                    <DataGrid
                                        className={classes.gridPaper}
                                        rows={rows}
                                        columns={columns}
                                        autoHeight={true}
                                        getRowHeight={() => "auto"}
                                        pageSize={20}
                                        // onPageChange={() => logger({type: "info", content: "TODO: get extra info from server"})}
                                        getRowId={(row) => row.id}
                                        sx={{
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1,
                                            },
                                        }}
                                        rowsPerPageOptions={[5, 10, 15, 20]}
                                    />
                                )}
                                <Typography variant="subtitle2" color="primary">
                                    <strong>Total de Atendimentos:</strong>{" "}
                                    {ticketsCount}
                                </Typography>
                            </Grid>
                        </div>
                    </div>
                </Paper>
            </MainContainer>
        </>
    );
}
