import React, { useEffect, useState } from "react";

import makeStyles from "@mui/styles/makeStyles";

import ModalImage from "react-modal-image";
import appVars from "../../../package.json";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    messageMedia: {
        objectFit: "cover",
        width: "30%",
        minWidth: "47b",
        borderRadius: theme.spacing(1),
    },
}));

const ModalImageCors = (props) => {
    const classes = useStyles();
    const [blobUrl, setBlobUrl] = useState("/default-image.png");

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!props.imageUrl) return;
        const fetchImage = async () => {
            try {
                if (appVars.storage === "local") {
                    const { data, headers } = await axiosPrivate.get(
                        props.imageUrl,
                        { responseType: "blob", referrerPolicy: "no-referrer" },
                        { signal }
                    );
                    const url = window.URL.createObjectURL(
                        new Blob([data], { type: headers["content-type"] })
                    );
                    setBlobUrl(url);
                } else {
                    fetch(props.imageUrl)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(
                                    i18n.t(
                                        "translation.modalImage.toasts.error"
                                    )
                                );
                            }
                            return response.blob();
                        })
                        .then((blob) => {
                            const url = window.URL.createObjectURL(blob);
                            setBlobUrl(url);
                        })
                        .catch((err) => {
                            toastError(
                                "ERR_MEDIA_NOT_REACHABLE",
                                "",
                                "While trying to fetch media on tickets."
                            );
                        });
                }
            } catch (err) {
                const getFileName = (mediaPath) => {
                    let breakPath = mediaPath.split("/");
                    let pathCount = breakPath.length - 1;
                    let fileName = breakPath[pathCount];
                    return fileName;
                };
                if (err.response.status === 404) {
                    let error = {
                        response: {
                            data: {
                                message: `ERR_MEDIA_NOT_FOUND|vars:{"image":"${getFileName(
                                    props.imageUrl
                                )}"}`,
                            },
                        },
                    };
                    toastError(error);
                } else {
                    toastError(err);
                }
            }
        };

        fetchImage();

        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.imageUrl]);

    return (
        <ModalImage
            className={classes.messageMedia}
            small={blobUrl}
            medium={blobUrl}
            large={blobUrl}
            showRotate="true"
            alt={props.alt}
        />
    );
};

export default ModalImageCors;
