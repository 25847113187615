import React from "react";

import { Avatar, Card, CardHeader, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Send } from "lucide-react";
import { grey } from '@mui/material/colors';
const useStyles = makeStyles((theme) => ({
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },

    dashCard: {
        width: "100%",
        maxWidth: "calc(100% / 3)",
        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
        },
    },
    dashCardInnerLeft: {
        [theme.breakpoints.down("md")]: {
            maxWidth: "65%",
            width: "100%",
            float: "left",
            flex: 1,
        },
    },
    dashCardInnerRight: {
        [theme.breakpoints.down("md")]: {
            maxWidth: "35%",
            width: "100%",
            float: "right",
            textAlign: "right",
            flex: 1,
            marginTop: "-5px",
        },
    },
    customFixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        height: 120,
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.05)",
        [theme.breakpoints.down("md")]: {
            height: 64,
            display: "inline-block",
            verticalAlign: "middle",
        },
    },
}));

export const DashCard = (props) => {
    const classes = useStyles();

    return (
        <Grid item className={classes.dashCard}>
            <Card className={classes.customFixedHeightPaper}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.cardAvatar}>
                            {props?.icon}
                           
                        </Avatar>
                    }
                    title={
                        <Typography
                            component="h3"
                            variant="h6"
                            color="primary"
                            paragraph
                            className={classes.dashCardInnerLeft}
                        >
                            {props?.title}
                        </Typography>
                    }
                    subheader={
                        <Typography component="h1" variant="h4">
                            {props?.children}
                        </Typography>
                    }
                ></CardHeader>
            </Card>
        </Grid>
    );
};

export default DashCard;
