import { Field, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

// Styling
import { Colorize } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

// Components
import BotSelect from "../BotSelect";
import ColorPicker from "../ColorPicker";
import CheckboxInput from "../FormComponents/CheckboxInput";
import FormContainer from "../FormComponents/FormContainer";
import GPTModalButton from "../GPTModalButton";
import InputVariables from "../InputVariables";

// Hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import Divider from "../Divider";
import MultiFieldLine from "../FormComponents/MultiFieldLine";


const useStyles = makeStyles(theme => ({
    accordion: {
        boxShadow: "none",
        border: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.23)"
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    maxHeight: {
        "& .MuiDialog-paper": {
            maxHeight: "calc(100% - 56px)"
        },
    },
    multFieldLineLeft: {
        display: "flex",
        justifyContent: "flex-start",
        // "& > *:not(:last-child)": {
        "& > *:last-child": {
            // marginRight: theme.spacing(1),
            marginRight: 0,
        },
        width: "100%",
    },
    labelPaddingInput: {
        marginTop: "-3px",
        "& input": {
            textAlign: "center"
        }
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    btnWrapper: {
        position: "relative",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
        borderRadius: 4
    },
    removeBottomMargin: {
        marginBottom: 0,
    },
}));

const QueueSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    color: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .max(9, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId, duplicate }) => {
    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();
    const fieldRef = useRef(null);
    const greetingRef = useRef();

    const initialState = {
        name: "",
        color: "",
        greetingMessage: "",
        checkAutoClose: false,
        alertTimer:1,
        autoCloseTimer: 10,
        autoCloseMessage: "",
        absenceMessage: "",
        checkWorkHours: true,

        enableBot: false,
        botId: "",

        startWork: "08:00",
        endWork: "12:00",
        startWorkSecondPeriod: "13:00",
        endWorkSecondPeriod: "18:00",

        startWorkWeekend: "",
        endWorkWeekend: "",
        startWorkWeekendSecondPeriod: "",
        endWorkWeekendSecondPeriod: "",

        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
        timeNewTicket: 30,

        checkSendNPS: false,
        NPSGreetingMessage: "",
        NPSFarewellMessage: "",
        checkSendNPSFeedback: false,
        checkAssignUserWithFewestCalls: false,
        NPSFeedbackMessage: "",
    };

    // State
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
    const [queue, setQueue] = useState(initialState);

    // Context
    const { tenantId } = useTenant();

    const handleFocus = (e) => {
        fieldRef.current = e.target;
    }

    const fetchQueue = useCallback(async () => {
        if (!queueId || !tenantId) return;
        try {
            const { data } = await axiosPrivate.get(`/${tenantId}/queue/${queueId}`);
            if (data?.botId === null) {
                data.botId = "";
            };
            setQueue((prev) => ({ ...prev, ...data }));
        } catch (err) {
            toastError(err);
        }
    }, [axiosPrivate, queueId, tenantId]);

    useEffect(() => {
        if (!open) return;
        fetchQueue();
    }, [fetchQueue, open])

    const handleClose = () => {
        onClose();
        setQueue(initialState);
    };

    const handleSaveQueue = async values => {
        if (!tenantId) return;

        // Update data values in case of an update to currently existing data.
        let { id, createdAt, updatedAt, ...queueData } = values;
        if (values.botId === "") queueData.botId = null

        try {
            if (duplicate === false && queueId) {
                await axiosPrivate.put(`/${tenantId}/queue/${queueId}`, queueData);
            } else {
                await axiosPrivate.post(`/${tenantId}/queue`, queueData);
            }
            toast.success(i18n.t("translation.queueModal.toasts.success"));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };

    return open && (
        <Dialog open={open} onClose={handleClose} className={classes.maxHeight}>
            <DialogTitle>
                {duplicate === false && queueId
                    ? `${i18n.t("translation.queueModal.title.edit")}`
                    : `${i18n.t("translation.queueModal.title.add")}`
                }
            </DialogTitle>
            <Formik
                initialValues={queue}
                enableReinitialize={true}
                validationSchema={QueueSchema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values) => {
                    handleSaveQueue(values);
                }}
            >
                {({ errors, getFieldProps, isSubmitting, setFieldValue, touched, values }) => (
                    <FormContainer>
                        <DialogContent dividers >
                            <MultiFieldLine>
                                <Field
                                    as={TextField}
                                    name="name"
                                    label={i18n.t("translation.queueModal.form.name")}
                                    autoFocus
                                    // onBlur={(e) => validateField(e.target.name)}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    className={classes.textField}
                                />
                                <Field
                                    as={TextField}
                                    label={i18n.t("translation.queueModal.form.color")}
                                    name="color"
                                    id="color"
                                    onFocus={() => {
                                        setColorPickerModalOpen(true);
                                        greetingRef.current.focus();
                                    }}
                                    error={touched.color && Boolean(errors.color)}
                                    helperText={touched.color && errors.color}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{ backgroundColor: values.color }}
                                                    className={classes.colorAdorment}
                                                ></div>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <IconButton
                                                size="small"
                                                color="default"
                                                onClick={() => setColorPickerModalOpen(true)}
                                            >
                                                <Colorize />
                                            </IconButton>
                                        ),
                                    }}
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                />
                                <ColorPicker
                                    open={colorPickerModalOpen}
                                    handleClose={() => setColorPickerModalOpen(false)}
                                    onChange={(color) => setFieldValue("color", color)}
                                />
                                <FormControlLabel
                                    control={
                                        <Field
                                            as={Switch}
                                            color="primary"
                                            name="enableBot"
                                            checked={values.enableBot}
                                        />
                                    }
                                    label={i18n.t("translation.whatsappModal.form.enableBot")}
                                />
                            </MultiFieldLine>
                            <Box sx={{ display: values.enableBot === false ? "none" : "block" }} >
                                <BotSelect
                                    current=""
                                    fieldId="botId"
                                />
                            </Box>
                            <Box sx={{ display: values.enableBot === true ? "none" : "block" }} >
                                <GPTModalButton
                                    disabled={isSubmitting}
                                    field={fieldRef.current || getFieldProps("greetingMessage")}
                                    setFieldValue={setFieldValue}
                                    prompt={i18n.t("translation.gptModal.prompts.queueGreetingMessage")}
                                />
                                <Field
                                    as={TextField}
                                    label={i18n.t("translation.queueModal.form.greetingMessage")}
                                    type="greetingMessage"
                                    multiline
                                    inputRef={greetingRef}
                                    minRows={3}
                                    fullWidth
                                    name="greetingMessage"
                                    onFocus={handleFocus}
                                    error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                                    helperText={touched.greetingMessage && errors.greetingMessage}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>

                            <InputVariables group="queues" field={fieldRef.current} setFieldValue={setFieldValue} />
                            <CheckboxInput
                                                    label={i18n.t("translation.queueModal.form.checkAssignUserWithFewestCalls.title")}
                                                    name="checkAssignUserWithFewestCalls"
                                                    className={classes.removeBottomMargin}
                                                />
                            <Divider silent={true} />

                            <div>
                                <Accordion className={classes.accordion} expanded={values.checkWorkHours}>
                                    <AccordionSummary>
                                        <Tooltip title={i18n.t("translation.queueModal.form.checkWorkHours.note")} placement="top">
                                            <>
                                                <CheckboxInput
                                                    label={i18n.t("translation.queueModal.form.checkWorkHours.title")}
                                                    name="checkWorkHours"
                                                    className={classes.removeBottomMargin}
                                                />
                                            </>
                                        </Tooltip>
                                    </AccordionSummary>

                                    <AccordionDetails className={classes.flexWrap}>
                                        <GPTModalButton
                                            disabled={isSubmitting}
                                            field={fieldRef.current || getFieldProps("absenceMessage")}
                                            setFieldValue={setFieldValue}
                                            prompt={i18n.t("translation.gptModal.prompts.queueAbsenceMessage")}
                                        />
                                        <Field
                                            as={TextField}
                                            label={i18n.t("translation.queueModal.form.checkWorkHours.absenceMessage")}
                                            type="absenceMessage"
                                            multiline
                                            minRows={3}
                                            fullWidth
                                            name="absenceMessage"
                                            onFocus={handleFocus}
                                            error={touched.absenceMessage && Boolean(errors.absenceMessage)}
                                            helperText={touched.absenceMessage && errors.absenceMessage}
                                            variant="outlined"
                                            size="small"
                                        />

                                        <Divider />

                                        <Typography>
                                            {i18n.t("translation.queueModal.form.checkWorkHours.workdays")}
                                        </Typography>
                                        <MultiFieldLine>
                                            <FormControl component="fieldset">
                                                <MultiFieldLine>
                                                    <CheckboxInput
                                                        label={i18n.t("translation.validation.weekdays.monday.abreviated")}
                                                        name="monday"
                                                    />
                                                    <CheckboxInput
                                                        label={i18n.t("translation.validation.weekdays.tuesday.abreviated")}
                                                        name="tuesday"
                                                    />
                                                    <CheckboxInput
                                                        label={i18n.t("translation.validation.weekdays.wednesday.abreviated")}
                                                        name="wednesday"
                                                    />
                                                    <CheckboxInput
                                                        label={i18n.t("translation.validation.weekdays.thursday.abreviated")}
                                                        name="thursday"
                                                    />
                                                    <CheckboxInput
                                                        label={i18n.t("translation.validation.weekdays.friday.abreviated")}
                                                        name="friday"
                                                    />
                                                </MultiFieldLine>
                                            </FormControl>
                                        </MultiFieldLine>
                                        <MultiFieldLine>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.checkWorkHours.startWork")}
                                                type="time"
                                                ampm="false"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ step: 600 }} //5 minutes
                                                name="startWork"
                                                error={touched.startWork && Boolean(errors.startWork)}
                                                helperText={touched.startWork && errors.startWork}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                className={classes.textField}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.checkWorkHours.endWork")}
                                                type="time"
                                                ampm="false"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ step: 600 }} //5 minutes
                                                fullWidth
                                                name="endWork"
                                                error={touched.endWork && Boolean(errors.endWork)}
                                                helperText={touched.endWork && errors.endWork}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                            />

                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.checkWorkHours.startWorkSecondPeriod")}
                                                type="time"
                                                ampm="false"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ step: 600 }} //5 minutes
                                                name="startWorkSecondPeriod"
                                                error={touched.startWorkSecondPeriod && Boolean(errors.startWorkSecondPeriod)}
                                                helperText={touched.startWorkSecondPeriod && errors.startWorkSecondPeriod}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                className={classes.textField}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.checkWorkHours.endWorkSecondPeriod")}
                                                type="time"
                                                ampm="false"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ step: 600 }} //5 minutes
                                                fullWidth
                                                name="endWorkSecondPeriod"
                                                error={touched.endWorkSecondPeriod && Boolean(errors.endWorkSecondPeriod)}
                                                helperText={touched.endWorkSecondPeriod && errors.endWorkSecondPeriod}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                            />
                                        </MultiFieldLine>
                                        <Divider silent={true} />

                                        <FormControl component="fieldset">
                                            <Typography>
                                                {i18n.t("translation.queueModal.form.checkWorkHours.weekends")}
                                            </Typography>
                                            <MultiFieldLine>
                                                <CheckboxInput
                                                    label={i18n.t("translation.validation.weekdays.saturday.abreviated")}
                                                    name="saturday"
                                                />
                                                <CheckboxInput
                                                    label={i18n.t("translation.validation.weekdays.sunday.abreviated")}
                                                    name="sunday"
                                                />
                                            </MultiFieldLine>
                                        </FormControl>
                                        <MultiFieldLine>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.checkWorkHours.startWorkWeekend")}
                                                type="time"
                                                ampm="false"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ step: 600 }} //5 minutes
                                                fullWidth
                                                name="startWorkWeekend"
                                                error={touched.startWorkWeekend && Boolean(errors.startWorkWeekend)}
                                                helperText={touched.startWorkWeekend && errors.startWorkWeekend}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.checkWorkHours.endWorkWeekend")}
                                                type="time"
                                                ampm="false"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ step: 600 }} //5 minutes
                                                fullWidth
                                                name="endWorkWeekend"
                                                error={touched.endWorkWeekend && Boolean(errors.endWorkWeekend)}
                                                helperText={touched.endWorkWeekend && errors.endWorkWeekend}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.checkWorkHours.startWorkWeekendSecondPeriod")}
                                                type="time"
                                                ampm="false"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ step: 600 }} //5 minutes
                                                fullWidth
                                                name="startWorkWeekendSecondPeriod"
                                                error={touched.startWorkSecondWeekend && Boolean(errors.startWorkSecondWeekend)}
                                                helperText={touched.startWorkWeekendSecondPeriod && errors.startWorkWeekendSecondPeriod}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.checkWorkHours.endWorkWeekendSecondPeriod")}
                                                type="time"
                                                ampm="false"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ step: 600 }} //5 minutes
                                                fullWidth
                                                name="endWorkWeekendSecondPeriod"
                                                error={touched.endWorkWeekendSecondPeriod && Boolean(errors.endWorkWeekendSecondPeriod)}
                                                helperText={touched.endWorkWeekendSecondPeriod && errors.endWorkWeekendSecondPeriod}
                                                variant="outlined"
                                                size="small"
                                                className={classes.textField}
                                            />
                                        </MultiFieldLine>
                                    </AccordionDetails >
                                </Accordion >
                            </div >
                            <Divider silent={true} />

                            <div>
                                <Accordion className={classes.accordion} expanded={values.checkAutoClose}>
                                    <AccordionSummary>
                                        <Tooltip title={i18n.t("translation.queueModal.form.autoClose.note")} placement="top">
                                            <>
                                                <CheckboxInput
                                                    label={i18n.t("translation.queueModal.form.autoClose.title")}
                                                    name="checkAutoClose"
                                                    className={classes.removeBottomMargin}
                                                />
                                            </>
                                        </Tooltip>
                                    </AccordionSummary>

                                    <AccordionDetails className={classes.flexWrap}>
                                        <div>
                                            <GPTModalButton
                                                disabled={isSubmitting}
                                                field={fieldRef.current || getFieldProps("autoCloseMessage")}
                                                setFieldValue={setFieldValue}
                                                prompt={i18n.t("translation.gptModal.prompts.autoCloseMessage")}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.autoClose.autoCloseTicket")}
                                                type="autoCloseMessage"
                                                multiline
                                                minRows={3}
                                                fullWidth
                                                name="autoCloseMessage"
                                                onFocus={handleFocus}
                                                error={touched.autoCloseMessage && Boolean(errors.autoCloseMessage)}
                                                helperText={touched.autoCloseMessage && errors.autoCloseMessage}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>

                                        <MultiFieldLine left={true}>
                                            <Typography>
                                                {i18n.t("translation.queueModal.form.autoClose.alertTime")}
                                            </Typography>
                                            <Field
                                                as={TextField}
                                                name="alertTimer"
                                                type="number"
                                                variant="standard"
                                                inputProps={{ min: 0, max: 1440 }}
                                                style={{ width: 50 }}
                                                className={classes.labelPaddingInput}
                                            />
                                            <Typography>
                                                {i18n.t("translation.queueModal.form.checkWorkHours.newTicketAfter.minutes")}
                                            </Typography>
                                        </MultiFieldLine>
                                        <MultiFieldLine left={true}>
                                            <Typography>
                                                {i18n.t("translation.queueModal.form.autoClose.autoCloseTime")}
                                            </Typography>
                                            <Field
                                                as={TextField}
                                                name="autoCloseTimer"
                                                type="number"
                                                variant="standard"
                                                inputProps={{ min: 0, max: 1440 }}
                                                style={{ width: 50 }}
                                                className={classes.labelPaddingInput}
                                            />
                                            <Typography>
                                                {i18n.t("translation.queueModal.form.checkWorkHours.newTicketAfter.minutes")}
                                            </Typography>
                                        </MultiFieldLine>

                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <Divider silent={true} />

                            <div>
                                <Accordion
                                    className={classes.accordion}
                                    expanded={values.checkSendNPS && (values.checkAutoClose ? values.autoCloseTimer > 0 : true)}
                                    disabled={values.checkAutoClose ? values.autoCloseTimer === 0 : false}
                                >
                                    <AccordionSummary>
                                        <Tooltip title={i18n.t("translation.queueModal.form.autoClose.note")} placement="top">
                                            <>
                                                <CheckboxInput
                                                    label={i18n.t("translation.queueModal.form.NPS.title")}
                                                    name="checkSendNPS"
                                                    className={classes.removeBottomMargin}
                                                />
                                            </>
                                        </Tooltip>
                                    </AccordionSummary>

                                    <AccordionDetails className={classes.flexWrap}>
                                        <div>
                                            <GPTModalButton
                                                disabled={isSubmitting}
                                                field={fieldRef.current || getFieldProps("NPSGreetingMessage")}
                                                setFieldValue={setFieldValue}
                                                prompt={i18n.t("translation.gptModal.prompts.NPS.greetingMessage")}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.NPS.greetingMessage")}
                                                type="NPSGreetingMessage"
                                                multiline
                                                minRows={3}
                                                fullWidth
                                                name="NPSGreetingMessage"
                                                onFocus={handleFocus}
                                                error={touched.NPSGreetingMessage && Boolean(errors.NPSGreetingMessage)}
                                                helperText={touched.NPSGreetingMessage && errors.NPSGreetingMessage}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>

                                        <div>
                                            <GPTModalButton
                                                disabled={isSubmitting}
                                                field={fieldRef.current || getFieldProps("NPSFarewellMessage")}
                                                setFieldValue={setFieldValue}
                                                prompt={i18n.t("translation.gptModal.prompts.NPS.farewellMessage")}
                                            />
                                            <Field
                                                as={TextField}
                                                label={i18n.t("translation.queueModal.form.NPS.farewellMessage")}
                                                type="NPSFarewellMessage"
                                                multiline
                                                minRows={3}
                                                fullWidth
                                                name="NPSFarewellMessage"
                                                onFocus={handleFocus}
                                                error={touched.NPSFarewellMessage && Boolean(errors.NPSFarewellMessage)}
                                                helperText={touched.NPSFarewellMessage && errors.NPSFarewellMessage}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>

                                        <div>
                                            <Tooltip title={i18n.t("translation.queueModal.form.NPS.note")} placement="top">
                                                <>
                                                    <CheckboxInput
                                                        label={i18n.t("translation.queueModal.form.NPS.checkFeedback")}
                                                        name="checkSendNPSFeedback"
                                                        className={classes.removeBottomMargin}
                                                    />
                                                </>
                                            </Tooltip>
                                        </div>
                                        <Accordion expanded={values.checkSendNPSFeedback} disabled={values.autoCloseTimer === 0}>
                                            <div style={{ display: values.checkSendNPSFeedback ? "block" : "none" }}>
                                                <GPTModalButton
                                                    disabled={isSubmitting}
                                                    field={fieldRef.current || getFieldProps("NPSFeedbackMessage")}
                                                    setFieldValue={setFieldValue}
                                                    prompt={i18n.t("translation.gptModal.prompts.NPS.feedbackMessage")}
                                                />
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("translation.queueModal.form.NPS.feedbackMessage")}
                                                    type="NPSFeedbackMessage"
                                                    multiline
                                                    minRows={3}
                                                    fullWidth
                                                    name="NPSFeedbackMessage"
                                                    onFocus={handleFocus}
                                                    error={touched.NPSFeebackMessage && Boolean(errors.NPSFeebackMessage)}
                                                    helperText={touched.NPSFeebackMessage && errors.NPSFeebackMessage}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </div>
                                        </Accordion>
                                    </AccordionDetails>
                                </Accordion>
                               
                            </div>
                            <Divider silent={true} />

                            <MultiFieldLine left={true}>
                                <Typography>
                                    {i18n.t("translation.queueModal.form.checkWorkHours.newTicketAfter.title")}
                                </Typography>
                                <Field
                                    as={TextField}
                                    name="timeNewTicket"
                                    type="number"
                                    variant="standard"
                                    inputProps={{ min: 0, max: 1440 }}
                                    style={{ width: 50 }}
                                    className={classes.labelPaddingInput}
                                />
                                <Typography>
                                    {i18n.t("translation.queueModal.form.checkWorkHours.newTicketAfter.minutes")}
                                </Typography>
                            </MultiFieldLine>
                           
                        </DialogContent >
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                className={classes.buttonColorError}
                                disabled={isSubmitting}
                                variant="outlined"
                            >
                                {i18n.t("translation.validation.buttons.cancel")}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                className={classes.btnWrapper}
                            >
                                {queueId
                                    ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                    : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                {isSubmitting && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </FormContainer >
                )}
            </Formik >
        </Dialog >
    );
};

export default QueueModal;
